table {
  border-collapse: collapse !important;
  width: 100%;
  font-size: 0.83vw;
  margin-bottom: 0rem !important;
}

table .heading,
table .data {
  color: black;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  padding-left: 0px;
}

table .heading {
  padding-top: 30px;
  font-size: 1vw;
  padding-bottom: 12px;
  color: var(--text-light, #A8927D);
  z-index: 1;
  font-size: 15px;
}

.roundedTable {
  border-radius: 24px !important;
  overflow-y: hidden;
  background: var(--white, #FFF);
}

.fixTableHead {
  overflow-y: auto;
  box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.fixTableHead .tableHead .heading {
  position: sticky;
  top: 0;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
}

td {
  border: none;
  vertical-align: middle;
}
td th{
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  padding-left: 2rem !important;
}
tr{
  height: 72px;
}