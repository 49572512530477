.card {
    border-radius: 24px;
    background: #FFF;
    text-align: center;
}

.registrationSuccessful {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.successInstruction {
    color: var(--Black, #000);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.welcome-component {
    background-image: url(../../images/background-image.png);
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
