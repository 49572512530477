.accordian .bi-chevron-down {
  position: relative;
  right: none;
}

.accordian {
  color: var(--text-light, #A8927D);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}