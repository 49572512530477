.icon {
    position: absolute;
    cursor: pointer;
    top:25%; 
    left:40%; 
}


.grid {
    border-radius: 24px;
    background: var(--White, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
  }

.document {
    margin: auto;
    border-radius: 24px;
    background: var(--White, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.document-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 16.41px;
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 30px;    
    text-align: left; 
    padding-top: 1%; 
}