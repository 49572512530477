/* The Modal (background) */
.modal {
  font-size: 16px;
  position: fixed;
  /* Stay in place */
  z-index: 2;
  /* Sit on top */
  padding-top: 363px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: white;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.content {
  position: relative;
  width: 30%;
  background-color: white;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  z-index: 2;
  border-radius: 30px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.header {
  padding-left: 26px;
  margin-top: 30px;
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.body {
  padding-left: 26px;
  padding-top: 8px;
}

.footer {
  background-color: white;
  color: white;
  padding: 20px 50px 4px 80px;
  border-radius: inherit;
}

/* Extra small devices */
@media only screen and (max-width: 576px) {
  .content {
    width: 100%;
  }
}

/* small devices */
@media only screen and (min-width: 576px) {
  .content {
    width: 100%;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content {
    width: 75%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .content {
    width: 60%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .content {
    width: 45%;
  }
}

@media only screen and (min-width: 1500px) {
  .content {
    width: 35%;
  }
}