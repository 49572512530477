.accordion-button {
    padding: 0;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A8927D'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed) {
    color: black;
    background: none !important;
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion {
    --bs-accordion-bg: none;
    padding: 0;
}

.accordion-body{
    padding: 0;
}
