.storage-renew-validity {
    color: #A8927D;
    border-radius: 24px;
    border: 1px solid var(--Sandrift, #A8927D);
    background: var(--White, #FFF);
    cursor: inherit;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    margin-left: 1%;
    padding-top: 4px;
    padding-left: 8px;
    width: 100%;
    padding-bottom: 4px;
    padding-right: 8px;
}

.storage-renew-validity:hover {
    border: 1px solid var(--Sandrift, #A8927D);
    color: var(--Sandrift, #A8927D);
}