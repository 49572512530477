.noOfLicenses {
    color: var(--Black, #000);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text {
    color: var(--Black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 576px) {
    .refreshBtn {
        padding-right: 37%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .instruction {
        margin-left: -14%;
    }

    .refreshBtn {
        padding-right: 12%;
    }
}

/* Extra large devices ( 1200px and up) */
@media only screen and (min-width: 1200px) {
    .instruction {
        margin-left: 1%;
        margin-right: 8%;;
    }
}