
.container-height{
    max-height: 55vh
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .container-height{
        max-height: 73vh
    }    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .container-height{
        max-height: 78vh
    }    
}