@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
body {
  background: 484848;;
  max-width: 100%;
  overflow: hidden;
}
* {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  box-sizing: border-box;
  font-style: normal;
  margin: 0;
  padding: 0;
  max-width: 100%;
  caret-color: transparent;
  /* background-color: black; */
}
input{
  caret-color: auto;
}
.form-select > option {
  background-color: white;
}
::-webkit-scrollbar {
  width: 5.5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  background: var(--Sandrift, #A8927D);
}