.buttonWrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #A8927D;
  border-radius: 24px;
  padding: 10px 30px;
  margin: 0px 5px;
  width: auto;
  height: fit-content;
  max-width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  color: var(--White, #FFF);
  text-align: center;
  font-weight: 500;
  line-height: normal;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
}

i {
  -webkit-text-stroke: 0.4px
}