 .menu-icon {
     cursor: pointer;
     color: #A8927D;
 }

 .moreButton{
    min-width:56px;
 }

 .disabled {
    pointer-events: none;
    opacity: 0.5;
}

 .dropdown-item {
     cursor: pointer;
     font-size: 15px;
     font-weight: 500;
     color: var(--text-light, #A8927D);;
 }
 .dropdown-item:hover {
    color:  var(--Sandrift, #A8927D);
    background: var(--White, #FFF);
 }

 .menu-icon.show{
    background-color: #A8927D;
    color: white;
    min-height: 52px;
    min-width: 52px;
    border-radius: 50%;
 }

 /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .menu-icon{
        margin-left: 23px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .menu-icon{
        margin-left: 0px;
    }
    .menu-icon.show{
        width: 50%;
        text-align: center;
    }
}