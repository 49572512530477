.storageInfo {
    color: var(--Black, #000);
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
}

.storagePercentage {
    color: var(--Black, #000);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text {
    color: var(--Black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.expired{
    color: #FF0F00;
    border-color: #FF0F00;
}