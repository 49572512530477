/* Overriding peroperties in ButtonWithIcon component */
.renew-textWrapper{
    padding-top: 4px;
}

.renew-buttonWrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #A8927D;
    border-radius: 24px;
    padding: 0px;
    margin: 0px 5px;
    width: auto;
    height: fit-content;
    max-width: fit-content;
    font-size: 14px;
    white-space: nowrap;
    color: var(--White, #FFF);
    text-align: center;
    font-weight: 500;
    line-height: normal;
  }
  
  .renew-iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    font-size: 1.3rem;
  }
  
  i {
    -webkit-text-stroke: 0.4px
  }