.clinic-name {
    font-weight: 600;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
     --bs-table-color-type: none; 
     --bs-table-bg-type: none;
     --bs-table-accent-bg: none;
     --bs-table-bg:none;
     color: none;    
}

.table-striped > tbody > tr:nth-child(2n+1) {
    background: var(--Row-Background-Gradient, linear-gradient(90deg, rgba(168, 146, 125, 0.00) 0%, rgba(168, 146, 125, 0.06) 16%, rgba(168, 146, 125, 0.06) 84%, rgba(168, 146, 125, 0.00) 100%));    
}
