.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.image2 {
  position: absolute;
  top: 0px;
  left: 50px;
  border-radius: 50%;
}

.line {
  opacity: 0.3;
  color: var(--Sandrift, #A8927D);
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .images-padding {
    padding-left: 20%;
  }

}

@media only screen and (min-width: 1440px) {
  .images-padding {
    padding-left: 60%;
  }

}