.textBox {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 24px;
    color: #A8927D;
    background: var(--planar-light, rgba(168, 146, 125, 0.16));
    border: none;
    width: 100%;
}

textarea:focus {
    outline: none;
}

textarea {
    resize: none;
}