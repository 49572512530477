
.react-tel-input .country-list{
    min-width: 300px;
}

.react-tel-input .form-control {
    border-radius: 24px;
    background: var(--planar-light, rgba(168, 146, 125, 0.16));
    min-height: 46px;
    border: none;
    color: var(--text-light, #A8927D);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: inherit;
}
.react-tel-input .flag-dropdown {
    background-color:none;
    border: none;
    background: none;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: none;
}
.react-tel-input .flag-dropdown.open .selected-flag{
    background: var(--planar-light, rgba(168, 146, 125, 0.16));
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}
.react-tel-input .selected-flag:hover{
    background: none;
}
.form-control:focus{
    box-shadow: none;
}