.website {
    color: #A8927D;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.copyright {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.app-version {
    color: #000;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.about-btn {
    color: #A8927D;
    border-radius: 24px;
    border: 2px solid rgba(168, 146, 125, 0.16);
    background: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin-left: 1%;
}
.about-btn:hover {
    border: 1px solid #A8927D;
    color: #A8927D;
}

.about-page #myModal{
    padding-top: 248px;
}

@media only screen and (min-width: 1500px) {
    .about-page #modalContent {
      width: 26%;
    }
  }