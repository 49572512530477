.content-layout{
    justify-content: center;
    margin-bottom: 14rem;
}

.content-wrapper{
    overflow: auto;
}
@media only screen and (min-width: 992px){
    .content-layout{
        max-width: 70%;
        margin-left:auto;
        margin-right:auto;
    }
    .content-wrapper{
        max-width: 100%;
        margin-left:auto;
        margin-right:auto;
        background: #f5f2f0;
        margin-top: 40px;
        height: 100vh;
    }
   
}

@media only screen and (max-width: 576px){
    .content-layout{
        max-width: 100%;
        margin-left:auto;
        margin-right:auto;
    }
    .content-wrapper{
        max-width: 100%;
        margin-left:auto;
        margin-right:auto;
        background: #f5f2f0;
        margin-top: 40px;
        height: 100vh;
    }
   
}