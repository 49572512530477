.gridview-node {
    border-radius: 24px;
    background: var(--White, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.gridview-node-header {

    font-weight: 500;
    font-size: 16px;
    line-height: 16.41px;
}

.grid-text-align {
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 0px;
    width: 97%;
}

.extra-text-right {
    color: var(--text-light, #A8927D);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}