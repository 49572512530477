.dropdown-toggle {
    border-radius: 24px;
    border: 2px solid var(--planar-light, rgba(168, 146, 125, 0.16));
    background: var(--white, #FFF);
    color: #A8927D;
    font-size: 14px;
    font-weight: 500;
    min-width: 121px;
    text-align: left;
}

.dropdown {
    min-height: 20px;
}

.btn.show {
    background-color: #A8927D;
    color: white
}

.dropdown-toggle::after {
    display: none;
}

.bi-chevron-down {
    position: absolute;
    right: 0.7rem;
}

.dropdown-menu.show {
    display: block;
    border-radius: 20px;
    background: var(--white, #FFF);
    padding: 12px 34px 12px 2px;
    text-align: left;
}

.dropdown-toggle:hover {
    border: 2px solid var(--planar-light, rgba(168, 146, 125, 0.16));
}