.noLicenseContainer {
    text-align: center;
    color: #A8927D;
    margin-top: 9%;
}

.text {
    color: #A8927D;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
}

.title {
    font-size: 21px;
    font-weight: 600;
    line-height: normal;
}