@media only screen and (max-width: 576px) {
    .width {
      width: 100%;
    }  
  }
  @media only screen and (min-width: 576px) {
    .width {
      width: 50%;
    } 
  }
  @media only screen and (min-width: 992px) {
    .width {
      width: 25%;
    }
  } 

  .disabled {
    pointer-events: none;
    opacity: 0.4;
}