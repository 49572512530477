table .align-center {
    color: black;
    padding-top: 0px !important;
    padding: 0 10%;
}
table{
    border-spacing: 10px;
}

.header {
    color: var(--Black, #000);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.releaseHistory {
    width: 30%
}

.releaseHistory tr {
    height: 50px;
}
.releaseHistory td{
    height: 60px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 576px) {
    .releaseHistory {
        width: 100%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .releaseHistory {
        width: 50%;
    }
}

@media only screen and (min-width: 1200px) {
    .releaseHistory {
        width: 30%;
    }
}


@media only screen and (min-width: 1400px) {
    .releaseHistory {
        width: 32%;
    }
}

