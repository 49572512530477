.success-msg {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    font-style: normal;
}

@media only screen and (min-width: 1500px) {
    .activate-license-container .modal_content__iS8Nl {
      width: 32%;
    }
  }