.appDownload {
    margin: auto;
    border-radius: 24px;
    background: var(--White, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.version {
    color: var(--Indigo-Dye, #5B6F82);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
}

.date {
    color: var(--Black, #000);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}