.label {
    color: var(--text-light, #A8927D);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-stroke: 0.1px;
}

.input-box {
    border-radius: 24px;
    background: var(--planar-light, rgba(168, 146, 125, 0.16));
    min-height: 46px;
    border: none;
    color: var(--text-light, #A8927D);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.input-box:focus{
    background: var(--planar-light, rgba(168, 146, 125, 0.16));
    color: var(--text-light, #A8927D);
}