.key {
    padding-right: 4%;
}

table .licenseType {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    vertical-align: middle;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.validity {
    color: #A8927D;
    border-radius: 24px;
    border: 1px solid var(--Sandrift, #A8927D);
    background: var(--White, #FFF);
    cursor: inherit;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    margin-left: 1%;
}

.entitlementId {
    color: var(--Sandrift, #A8927D);
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.validity:hover {
    border: 1px solid var(--Sandrift, #A8927D);
    color: var(--Sandrift, #A8927D);
}

.expired{
    color: #FF0F00;
    border-color: #FF0F00;
}

.expired:hover{
    color: #FF0F00;
    border-color: #FF0F00;
}