.input-field {
    border-radius: 24px;
    border: none
}

.input-container {
    border: 1px solid #a8927d;
    border-radius: 24px;
    width: fit-content;
    padding: 3px;
}

.submit-button {
    background: linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #a8927d 100%);
    height: 34px;
    width: 51px;
    border: none;
    color: white;
    border-radius: 24px;
    font-size: 12px;
    margin-top: 1px;
}

.icon-color {
    color: white;
}

.input-field:focus {
    box-shadow: none !important;
}

.check-icon {
    color: white;
}

.submit-button[disabled] {
    opacity: 0.6;
}