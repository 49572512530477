.device-row {
    border-radius: 24px;
    background: var(--white, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
    height: 158px;
}

.moreOption-container{
    justify-content: right;
}

.device-col {
    text-align: center !important;
}

.serial-number {
    font-size: 16px;
    font-weight: 600;
}

.firmware-version {
    font-size: 14px;
    font-weight: 600;
}

.spinner-margin {
    color: #A8927D;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .spinner-margin {
        margin-left: 33px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .spinner-margin {
        margin-left: 45px;
    }

}

@media only screen and (min-width: 1440px) {
    .spinner-margin {
        margin-left: 60px;
    }

}


@media only screen and (min-width: 1837px) {
    .spinner-margin {
        margin-left: 100px;
    }

}

@media only screen and (min-width: 2560px) {
    .spinner-margin {
        margin-left: 126px;
    }

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .product-name {
        font-size: 16px;
    }

    .serial-number {
        font-size: 13px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .product-name {
        font-size: 20px;
    }

    .serial-number {
        font-size: 16px;
    }
}


.disabled {
    pointer-events: none;
    opacity: 0.6;
}
