@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap');

.header-container {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.container {
  max-width: 100%;
}
.title-font {
  font-size: 38px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  text-align: center;
  white-space: nowrap;
  color: var(--Sandrift, #a8927d);
  line-height: normal;
}
.portal-name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  text-align: left;
  vertical-align: middle;
  color: #a8927d;
  white-space: nowrap;
}
.logo {
  max-width: 97px;
  max-height: 29px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .title-font {
    font-size: 32px;
  }
  .header-container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .title-font {
    font-size: 34px;
  }
  .header-container {
    margin-left: 2rem;
    margin-right: 1rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .title-font {
    font-size: 38px;
  }
  .header-container {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}
